<template>
  <div class="house-detail-container">
    <Header />
    <div class="base-info-wrapper">
      <div>
        <p class="house-name">{{ house && house.title }}</p>
      </div>
      <span
        class="foucs-txt"
        v-if="house && !house.isOwn"
        @click="doLike(house.houseId)"
      >
        <svg-icon
          class="icon"
          :icon-class="!(house && house.isFollow) ? 'like' : 'xihuan-a'"
        ></svg-icon>
        <span>{{ house && house.isFollow ? "已关注" : "关注该房源" }}</span>
      </span>
    </div>
    <div class="main-content-wrapper">
      <div class="content-left">
        <div class="image-wrapper">
          <img
            class="main-img"
            :src="currentImg || (house && house.coverImg)"
            alt=""
          />
          <el-carousel
            indicator-position="none"
            :interval="4000"
            type="card"
            height="200px"
            @change="doChangeCarousel"
          >
            <el-carousel-item
              v-for="item in house && house.imgList"
              :key="item.sort"
            >
              <el-image :src="item.imgUrl" alt="" />
            </el-carousel-item>
          </el-carousel>
        </div>
        <div class="house-bottom-info">
          <p class="title">房源基本信息</p>
          <div class="flex-wrapper">
            <div>
              <div class="info-other-info">
                <p class="info-label">房屋户型</p>
                <span>{{ house && house.unitType }}</span>
              </div>
              <div class="info-other-info">
                <p class="info-label">房屋朝向</p>
                <span>{{ house && house.towards }}</span>
              </div>
              <div class="info-other-info">
                <p class="info-label">装修情况</p>
                <span>{{ house && house.decoration }}</span>
              </div>
              <div class="info-other-info">
                <p class="info-label">说明</p>
                <span>{{ house && house.descs }}</span>
              </div>
            </div>
            <div>
              <div class="info-other-info">
                <p class="info-label">所在楼层</p>
                <span
                  >{{ house && house.stayFloor }}楼/共{{
                    house && house.totalFloor
                  }}层</span
                >
              </div>
              <div class="info-other-info">
                <p class="info-label">梯户比例</p>
                <span>{{ house && house.proportion }}</span>
              </div>
              <div class="info-other-info">
                <p class="info-label">有无电梯</p>
                <span>{{ house && house.elevatorFlag ? "有" : "无" }}</span>
              </div>
            </div>
            <div
              style="
                width: 100%;
                display: flex;
                justify-content: flex-start;
                align-items: center;
              "
            >
              <p
                style="
                  display: inline-block;
                  text-align: left;
                  width: 100px;
                  font-weight: bold;
                "
              >
                价格变动
              </p>
              <el-table
                v-if="house"
                :data="house.housePrices"
                style="color: #333"
                border
              >
                <el-table-column label="创建时间" align="center" prop="year">
                  <template slot-scope="scope">
                    <span>{{ toLocaleDateString(scope.row.year) }}</span>
                  </template>
                </el-table-column>
                <el-table-column label="价格" align="center" prop="price">
                  <template slot-scope="scope">
                    <span>{{ scope.row.price / 10000 }}万</span>
                  </template>
                </el-table-column>
              </el-table>
            </div>
          </div>
        </div>
      </div>
      <div class="content-right">
        <div class="right-base-info" v-if="type === 1">
          <span class="price">{{ house && house.sellPrice / 10000 }}万</span>
          <span class="unit-price"
            >单价{{ house && house.unitPrice }}元 / 平米</span
          >
        </div>
        <div class="right-base-info" v-else>
          <span class="price">{{ house && house.sellPrice }}元/月</span>
          <span class="unit-price"
            >{{ (house && house.payType) || "月付" }}价</span
          >
        </div>
        <div v-if="type === 1" class="right-base-info flex-info">
          <div>
            <p>{{ house && house.unitType }}</p>
            <span
              >{{ house && house.stayFloor }}楼/共{{
                house && house.totalFloor
              }}层</span
            >
          </div>
          <div>
            <p>{{ house && house.towards }}</p>
            <span>{{ house && house.decoration }}</span>
          </div>
          <div>
            <p>{{ house && house.rentArea }}m²</p>
            <span>建房日期{{ house && house.buildingDate }}</span>
          </div>
        </div>
        <!--        <div v-else class="right-base-info flex-info">-->
        <!--          <div>-->
        <!--            <p>主卧</p>-->
        <!--            <span>近地铁，出行方便</span>-->
        <!--          </div>-->
        <!--          <div>-->
        <!--            <p>15m²</p>-->
        <!--            <span>精装修，拎包入住</span>-->
        <!--          </div>-->
        <!--          <div>-->
        <!--            <p>有电梯</p>-->
        <!--            <span>建房日期 {{ house && house.buildingDate }}</span>-->
        <!--          </div>-->
        <!--        </div>-->
        <div class="info-other-info">
          <p class="info-label">小区名称</p>
          <span>{{ house && house.community }}</span>
        </div>
        <div class="info-other-info">
          <p class="info-label">所在区域</p>
          <span>{{ house && house.areaInfo }}</span>
        </div>
        <div class="info-other-info">
          <p class="info-label">房屋面积</p>
          <span>{{ house && house.rentArea }}m²</span>
        </div>
        <div class="info-other-info">
          <p class="info-label">房屋朝向</p>
          <span>{{ house && house.towards }}</span>
        </div>
        <div class="info-other-info">
          <p class="info-label">装修情况</p>
          <span>{{ house && house.decoration }}</span>
        </div>
        <div class="info-other-info">
          <p class="info-label">可看房时间</p>
          <span>{{ house && house.checkInDate }}</span>
        </div>
        <div class="info-other-info">
          <p class="info-label">房源编号</p>
          <span>{{ house && house.houseCode }}</span>
        </div>
        <div class="info-other-info">
          <p class="info-label">房东称呼</p>
          <span>{{ house && house.nickName }}</span>
        </div>
        <div class="info-other-info">
          <p class="info-label">房东手机号</p>
          <span>{{ house && house.phone }}</span>
          <span
            v-if="house && house.phone.indexOf('*') > -1"
            @click="doGetPhone"
            class="look-phone"
            >点开查看房东电话</span
          >
        </div>
        <div class="info-other-info">
          <p class="info-label">详细地址</p>
          <span>{{ house && house.addressDescs }}</span>
        </div>
        <el-dialog :visible.sync="dialogVisible" title="提示" width="35%">
          <p style="margin-bottom: 20px;">是否支付10元查看房东电话？</p>
          <el-radio-group v-model="radio">
            <el-radio label="1">梦币余额：{{ myPrice.signAmount }}</el-radio>
            <el-radio label="2">现金余额：{{ myPrice.availableAmount }}</el-radio>
          </el-radio-group>
          <span slot="footer" class="dialog-footer">
          <el-button @click="dialogVisible = false">取 消</el-button>
          <el-button type="primary" @click="handleConfirm">确 定</el-button>
  </span>
        </el-dialog>
      </div>
    </div>
  </div>
</template>

<script>
import Header from "@/components/Header.vue";
export default {
  name: "HouseDetail",
  components: { Header },
  data() {
    return {
      dialogVisible:false,
      radio:'1',
      myPrice:{
        availableAmount:0,
        signAmount:0
      },
      type: 1, // 1 售房  2 租房
      house: null,
      currentImg: "",
    };
  },
  mounted() {
    this.doInitHouse();
  },
  methods: {
    toLocaleDateString(dateStr) {
      const dateObj = new Date(dateStr);

      const options = { year: "numeric", month: "2-digit", day: "2-digit" };
      const formattedDate = dateObj.toLocaleDateString("zh-CN", options);
      return formattedDate;
    },

    doChangeCarousel(idx) {
      if (this.house) {
        this.currentImg = this.house.imgList[idx].imgUrl;
      }
    },
    async doInitHouse() {
      const { houseId, type } = this.$route.query;
      this.type = Number(type);
      if (houseId) {
        await this.doFindHouseDetail(houseId);
      }
    },
    doLike(houseId) {
      const token = localStorage.getItem("token");
      if (!token) {
        this.$message.warning("请先登录！");
        return;
      }
      this.$request
        .post("/api/house/updateCanOrAtt", { houseId })
        .then((res) => {
          if (res.code === 200) {
            this.$message.success(res.msg);
            this.doFindHouseDetail(this.house.houseId);
          } else {
            this.$message.warning(res.msg);
          }
        });
    },
    doGetPhone() {
      const token = localStorage.getItem("token");
      if (!token) {
        this.$message.warning("请先登录！");
        return;
      }
      this.$request.get("/api/user/getUserAccount").then((res) => {
        if (res.code === 200) {
          this.myPrice.availableAmount = res.data.availableAmount;
          this.myPrice.signAmount = res.data.signAmount;
          this.dialogVisible = true;
        }
      });
    },
    handleConfirm() {
      this.dialogVisible = false;
        this.$request
                .post("/api/house/getHousePhone", {
                  houseId: this.house && this.house.houseId,type:this.radio
                })
                .then((res) => {
                  if (res.code === 200) {
                    this.$message.success(res.msg);
                    this.doInitHouse();
                  } else {
                    this.$message.warning(res.msg);
                  }
                  this.radio='1';
                });
    },



    async doFindHouseDetail(houseId) {
      this.$request.get("/api/house/getHouseInfo", { houseId }).then((res) => {
        if (res.code === 200) {
          this.house = res.data;
        } else {
          this.house = null;
        }
      });
    },
  },
};
</script>
<style lang="scss">
@import "../styles/variable.scss";
.house-detail-container {
  width: 1200px;
  margin: 0 auto;
  .base-info-wrapper {
    margin: 20px 0;
    display: flex;
    justify-content: space-between;
    color: $textColor;
    .foucs-txt {
      cursor: pointer;
    }
    .icon {
      margin-right: 10px;
    }
    .house-name {
      font-size: 24px;
      font-weight: bold;
      color: $mainColor;
      margin-bottom: 20px;
    }
  }
  .main-content-wrapper {
    display: flex;
    justify-content: space-between;
    padding: 20px 0;
    // border: 1px solid $mainColor;
    > div {
      width: 46%;
    }
  }
  .content-left {
    .image-wrapper {
      width: 500px;
      .main-img {
        width: 500px;
        height: 300px;
      }
    }
    .house-bottom-info {
      margin-top: 30px;
      .title {
        font-size: 20px;
        font-weight: bold;
        padding-bottom: 10px;
        border-bottom: 1px solid $defaultColor;
      }
      .flex-wrapper {
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;
        > div {
          width: 50%;
          .info-other-info {
            padding: 15px 0;
            p {
              display: inline-block;
              width: 100px;
              text-align: left;
              font-weight: bold;
            }
            span {
              color: $textColor;
            }
            .look-phone {
              color: $mainColor;
              margin-left: 50px;
              cursor: pointer;
            }
          }
        }
      }
    }
  }
  .content-right {
    .right-base-info {
      padding: 20px;
      border-bottom: 1px solid $defaultColor;
      &:first-child {
        padding-top: 0;
      }
      .price {
        font-size: 28px;
        font-weight: bold;
        color: $redColor;
      }
      .unit-price {
        margin-left: 30px;
        color: $textColor;
      }
    }
    .flex-info {
      display: flex;
      justify-content: space-between;
      align-items: center;
      div {
        white-space: nowrap;
        &:not(:first-child) {
          margin-left: 30px;
        }
        p {
          font-size: 22px;
          font-weight: bold;
          color: $mainColor;
          margin-bottom: 15px;
        }
        span {
          color: $textColor;
        }
      }
    }
    .info-other-info {
      padding: 15px 20px;
      p {
        display: inline-block;
        width: 150px;
        text-align: left;
        font-weight: bold;
      }
      span {
        color: $textColor;
      }
      .look-phone {
        color: $mainColor;
        margin-left: 50px;
        cursor: pointer;
      }
    }
  }
}
.el-carousel__container {
  width: 500px;
  img {
    width: 100%;
    height: 100%;
  }
}
</style>
